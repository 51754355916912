* {
  box-sizing: border-box;
}

.error {
  color: red;
}

.success {
  color: green;
}